<template>
  <main
    class="login-layout"
    v-if="!loggedIn"
    :class="{ [pageClass]: pageClass }"
  >
    <section class="content">
      <div class="login-block-wrapper">
        <large-logo></large-logo>
        <slot />
      </div>
    </section>
  </main>
  <screen-modal
    v-if="!!successMessage"
    class="confirm-modal"
    type="success"
    :confirm-action="resetSuccessMessage"
    :confirm-button-label="displayLabelName('global', 'mobile-screen', 'ok')"
    :cancel-button-label="displayLabelName('global', 'mobile-screen', 'cancel')"
    :show="!!successMessage"
    @close="resetSuccessMessage"
    >{{ successMessage }}</screen-modal
  >
</template>

<script>
import LargeLogo from "@/components/global/LargeLogo";
import { mapState } from "vuex";
export default {
  name: "Login-layout",
  computed: {
    ...mapState("auth", ["successMessage", "loggedIn"]),
    routeName() {
      if (this.$route && this.$route.name) {
        return this.$route.name;
      }
      return null;
    }
  },
  methods: {
    resetSuccessMessage() {
      this.$store.commit("auth/setSuccessMessage", null, { root: true });
      if (this.routeName && this.routeName === "r_reset-password") {
        setTimeout(() => {
          this.$router.push({ name: "r_login" });
        }, 0);
      }
    }
  },
  components: {
    LargeLogo
  },
  props: {
    pageClass: {
      required: false,
      type: String
    },
    pageTitle: {
      required: true,
      type: String
    },
    icon: {
      required: true
    }
  }
};
</script>
